import axiosClient from "./axiosClient";

export function fetchInvoices(formData) {
  return axiosClient
    .get("invoices", { params: formData })
    .then((response) => response.data);
}

export function downloadInvoice(id) {
  return axiosClient
    .get(`invoices/${id}/generate-invoice`, { responseType: "blob" })
    .then((response) => response.data);
}

export function fetchInvoiceById(id) {
  return axiosClient.get(`invoices/${id}`).then((response) => response.data);
}

export function insertInvoice(data) {
  return axiosClient.post("invoices", data).then((response) => response.data);
}

export function updateInvoice(id, data) {
  return axiosClient
    .put(`invoices/${id}`, data)
    .then((response) => response.data);
}

export function deleteInvoice(id) {
  return axiosClient.delete(`invoices/${id}`).then((response) => response.data);
}

export function fetchInvoiceId() {
  return axiosClient
    .get("invoice-static-datas/invoice-id")
    .then((response) => response.data);
}

export function fetchInvoiceStaticData() {
  return axiosClient
    .get("invoice-static-datas")
    .then((response) => response.data);
}

export function updateInvoiceStaticData(data) {
  return axiosClient
    .put(`invoice-static-datas`, data)
    .then((response) => response.data);
}

export function changeInvoiceStatus(id) {
  return axiosClient.patch(`invoices/${id}/status`);
}
