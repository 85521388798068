import * as invoices from "./invoices";
import * as roles from "./roles";
import * as users from "./users";
import * as auth from "./auth";
import * as inventories from "./inventories";
import * as employees from "./employees";
import * as sizes from "./sizes";
import * as products from "./products";
import * as productOrders from "./productOrders";

export default {
  ...invoices,
  ...roles,
  ...users,
  ...auth,
  ...inventories,
  ...employees,
  ...sizes,
  ...products,
  ...productOrders
};
