import axiosClient from "./axiosClient";

export function fetchInterconnectionItems(formData) {
  return axiosClient
    .get("inventory/interconnections", { params: formData })
    .then((response) => response.data);
}

export function insertInterconnectionItem(data) {
  return axiosClient.post("inventory/interconnections", data).then((response) => response.data);
}

export function fetchInterconnectionItemById(id) {
  return axiosClient.get(`inventory/interconnections/${id}`).then((response) => response.data);
}

export function changeInterconnectionItemStatus(id) {
  return axiosClient.patch(`inventory/interconnections/${id}/status`);
}

export function updateInterconnectionItem(id, data) {
  return axiosClient
    .put(`inventory/interconnections/${id}`, data)
    .then((response) => response.data);
}

export function deleteInterconnectionItem(id) {
  return axiosClient.delete(`inventory/interconnections/${id}`).then((response) => response.data);
}

export function fetchAntennaItems(formData) {
  return axiosClient
    .get("inventory/antennas", { params: formData })
    .then((response) => response.data);
}

export function insertAntennaItem(data) {
  return axiosClient.post("inventory/antennas", data).then((response) => response.data);
}

export function fetchAntennaItemById(id) {
  return axiosClient.get(`inventory/antennas/${id}`).then((response) => response.data);
}

export function changeAntennaItemStatus(id) {
  return axiosClient.patch(`inventory/antennas/${id}/status`);
}

export function updateAntennaItem(id, data) {
  return axiosClient
    .put(`inventory/antennas/${id}`, data)
    .then((response) => response.data);
}

export function deleteAntennaItem(id) {
  return axiosClient.delete(`inventory/antennas/${id}`).then((response) => response.data);
}

export function fetchPortItems(formData) {
  return axiosClient
    .get("inventory/ports", { params: formData })
    .then((response) => response.data);
}

export function insertPortItem(data) {
  return axiosClient.post("inventory/ports", data).then((response) => response.data);
}

export function fetchPortItemById(id) {
  return axiosClient.get(`inventory/ports/${id}`).then((response) => response.data);
}

export function changePortItemStatus(id) {
  return axiosClient.patch(`inventory/ports/${id}/status`);
}

export function updatePortItem(id, data) {
  return axiosClient
    .put(`inventory/ports/${id}`, data)
    .then((response) => response.data);
}

export function deletePortItem(id) {
  return axiosClient.delete(`inventory/ports/${id}`).then((response) => response.data);
}

export function fetchBrxAndAikItems(formData) {
  return axiosClient
    .get("inventory/brx-and-aiks", { params: formData })
    .then((response) => response.data);
}

export function insertBrxAndAikItem(data) {
  return axiosClient.post("inventory/brx-and-aiks", data).then((response) => response.data);
}

export function fetchBrxAndAikItemById(id) {
  return axiosClient.get(`inventory/brx-and-aiks/${id}`).then((response) => response.data);
}

export function changeBrxAndAikItemStatus(id) {
  return axiosClient.patch(`inventory/brx-and-aiks/${id}/status`);
}

export function updateBrxAndAikItem(id, data) {
  return axiosClient
    .put(`inventory/brx-and-aiks/${id}`, data)
    .then((response) => response.data);
}

export function deleteBrxAndAikItem(id) {
  return axiosClient.delete(`inventory/brx-and-aiks/${id}`).then((response) => response.data);
}

export function fetchCableItems(formData) {
  return axiosClient
    .get("inventory/cables", { params: formData })
    .then((response) => response.data);
}

export function insertCableItem(data) {
  return axiosClient.post("inventory/cables", data).then((response) => response.data);
}

export function fetchCableItemById(id) {
  return axiosClient.get(`inventory/cables/${id}`).then((response) => response.data);
}

export function changeCableItemStatus(id) {
  return axiosClient.patch(`inventory/cables/${id}/status`);
}

export function updateCableItem(id, data) {
  return axiosClient
    .put(`inventory/cables/${id}`, data)
    .then((response) => response.data);
}

export function deleteCableItem(id) {
  return axiosClient.delete(`inventory/cables/${id}`).then((response) => response.data);
}

export function fetchServicesPointItems(formData) {
  return axiosClient
    .get("inventory/services-points", { params: formData })
    .then((response) => response.data);
}

export function insertServicesPointItem(data) {
  return axiosClient.post("inventory/services-points", data).then((response) => response.data);
}

export function fetchServicesPointItemById(id) {
  return axiosClient.get(`inventory/services-points/${id}`).then((response) => response.data);
}

export function changeServicesPointItemStatus(id) {
  return axiosClient.patch(`inventory/services-points/${id}/status`);
}

export function updateServicesPointItem(id, data) {
  return axiosClient
    .put(`inventory/services-points/${id}`, data)
    .then((response) => response.data);
}

export function deleteServicesPointItem(id) {
  return axiosClient.delete(`inventory/services-points/${id}`).then((response) => response.data);
}

export function fetchDataCabinItems(formData) {
  return axiosClient
    .get("inventory/data-cabins", { params: formData })
    .then((response) => response.data);
}

export function insertDataCabinItem(data) {
  return axiosClient.post("inventory/data-cabins", data).then((response) => response.data);
}

export function fetchDataCabinItemById(id) {
  return axiosClient.get(`inventory/data-cabins/${id}`).then((response) => response.data);
}

export function changeDataCabinItemStatus(id) {
  return axiosClient.patch(`inventory/data-cabins/${id}/status`);
}

export function updateDataCabinItem(id, data) {
  return axiosClient
    .put(`inventory/data-cabins/${id}`, data)
    .then((response) => response.data);
}

export function deleteDataCabinItem(id) {
  return axiosClient.delete(`inventory/data-cabins/${id}`).then((response) => response.data);
}

export function fetchLifItems(formData) {
  return axiosClient
    .get("inventory/lifs", { params: formData })
    .then((response) => response.data);
}

export function insertLifItem(data) {
  return axiosClient.post("inventory/lifs", data).then((response) => response.data);
}

export function fetchLifItemById(id) {
  return axiosClient.get(`inventory/lifs/${id}`).then((response) => response.data);
}

export function changeLifItemStatus(id) {
  return axiosClient.patch(`inventory/lifs/${id}/status`);
}

export function updateLifItem(id, data) {
  return axiosClient
    .put(`inventory/lifs/${id}`, data)
    .then((response) => response.data);
}

export function deleteLifItem(id) {
  return axiosClient.delete(`inventory/lifs/${id}`).then((response) => response.data);
}